<template>
    <KCourse loader-class="MBcont" course-id="2" title="Анатомия тела" :items="items">
        <div class="MBtextcont">
            <p>Ну как, вы еще в состоянии воспринимать информацию? А продолжать лепить? Вас ждет детальная проработка кисти!</p>
            <p>Я сама не ожидала, что такая маленькая часть тела таит в себе столько информации… Урок получился очень объемным и подробным. Даже не знаю хорошо это или плохо. Решать вам )</p>
            <p>И еще такой момент. Про ноготки. Я их очень редко леплю, в основном я их расписываю, то есть просто рисую. Это мой подход, поэтому в видео вы не найдёте эту информацию. Но я думаю это не будет для вас проблемой ) Вы же такие замечательные, трудолюбивые и талантливые!</p>
            <p class='nomargin'><b> На этом занятии нам понадобятся:</b></p>
            <ol>
                <li>Пластик La Doll</li>
                <li>Кисть синтетическая, плоская</li>
                <li>Стеки для мелкой проработки</li>
                <li>Карандаш</li>
                <li>Чертеж кисти</li>
                <li>Надфиля, шкурка разной зернистости</li>
                <li>Макетный нож</li>
                <li>Следок</li>
                <li>Вода</li>
            </ol>   
        </div>

        <VideoView video-id="28b05171f74541cbb17525d4cd7130fd"/>

        <div class="MBtextcont">
            <p class='nomargin'><b>Задание:</b></p>
            <p>Проработать кисть и отшкурить. Придать ей окончательный вид.</p>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Готовим трафареты", url:"/mycourses/body/1"},
                { title: "Заготовки из пластилина", url:"/mycourses/body/2"},
                { title: "Подготовка к обтяжке", url:"/mycourses/body/3"},
                { title: "Обтяжка пластиком", url:"/mycourses/body/4"},
                { title: "Достаем пластилин", url:"/mycourses/body/5"},
                { title: "Основа кисти", url:"/mycourses/body/6"},
                { title: "Основа стопы", url:"/mycourses/body/7"},
                { title: "Анатомия руки", url:"/mycourses/body/8"},
                { title: "Анатомия кисти", url:"/mycourses/body/9"},
                { title: "Анатомия ноги", url:"/mycourses/body/10"},
                { title: "Анатомия стоп", url:"/mycourses/body/11"},
                { title: "Анатомия тела", url:"/mycourses/body/12"},
            ],
        }
        }
    }
</script>